<div [style.width.px]="width" style="display: contents" class="toolBar" #fixedHeader>
  <!--  [ngStyle]="production ? prodHeaderStyle : headerStyle"-->
  <mat-toolbar class="topbar">


    <button
      mat-mini-fab
      color="inherit"
      (click)="toggleSideNav()"
      class="d-flex justify-content-center"
    >
      <i
        class="iconify icon-22 d-flex"
        data-icon="solar:hamburger-menu-line-duotone"
      ></i>
    </button>


    <span class="toolBar-spacer"></span>
    <div style="display: flex ; align-items: center"
         [style.margin-right]="_translate.currentLang !== 'ar' ? '20px' : '0'"
         [style.margin-left]="_translate.currentLang === 'ar' ? '20px' : '0'">

      @if (currentUser.isFreeTrial) {
        <button
          mat-mini-fab
          color="inherit"
          class="d-flex justify-content-center"
        >
    <span [matBadge]="currentUser.remainingDay"  [matBadgeHidden]="!currentUser.isFreeTrial"
          matBadgeSize="small">
      <i class="iconify icon-22 d-flex" data-icon="fluent:hourglass-24-regular"></i>
    </span>
        </button>
      }

      @if (userType.isCustomer()) {
        <div tourAnchor="header.first">
          <button
            style="margin: 0 2px"
            mat-mini-fab
            color="inherit"
            (click)="openSub()"
            class="d-flex justify-content-center"
          >
            <!-- <i
              class="iconify icon-24 d-flex"
              data-icon="material-symbols:settings-applications-outline"
            ></i> -->
            <i class="iconify icon-22 d-flex" data-icon="material-symbols:card-membership-outline" style="font-size: 22px; color: inherit;"></i>
          </button>
        </div>

      }
      @if (userType.isCustomer()) {
        <div tourAnchor="header.second">
          <button
            style="margin: 0 2px"
            mat-mini-fab
            color="inherit"
            (click)="openCustomerProgressDialog()"
            class="d-flex justify-content-center"
          >
            <!-- <i
              class="iconify icon-22 d-flex"
              data-icon="material-symbols:card-membership-outline"
            ></i> -->
            <i class="iconify icon-22 d-flex" data-icon="heroicons-outline:cog" style="font-size: 22px; color: inherit;"></i>

          </button>
        </div>
      }


      <button
        mat-mini-fab
        color="inherit"
        aria-label="lightdark"
        class="d-flex justify-content-center"
        (click)="toggleTheme()"
      >
        <i-tabler
          class="d-flex icon-22"
          [name]="themeService.isDarkMode  ? 'sun' : 'moon'"
        ></i-tabler>
      </button>


      <notifications></notifications>
      <app-user-menu></app-user-menu>
      <app-languages></app-languages>
      <wifi-connection></wifi-connection>
    </div>
  </mat-toolbar>
</div>
