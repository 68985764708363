<div class="popup-overlay" *ngIf="isVisible">
  <div class="popup-card">
    <button class="close-btn" (click)="closePopup()">×</button>
    <div class="icon">
      <img src="assets/images/event-management.gif" alt="Event Management" />
    </div>
    <p class="message-container">
      {{ "LOGIN.ACCOUNT-ACTIVATION-MSSGE" | translate }}
  </div>
</div>
