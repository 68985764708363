@if (folded) {
<div class="height-60 p-3 width-65 d-flex align-items-center justify-content-center m-b-20" routerLink="/">
  <img class="width-40" [src]="logoImagePath" style="margin: 0 3px" alt="" />
</div>
} @else {
<div style="
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  ">
  <div class="logo" routerLink="/">
    <img [src]="logoImagePath" style="margin: 0 3px" alt="" />
  </div>
</div>
} @if (!folded) {
<div class="d-flex align-items-center justify-content-center">
  <h6 class="mat-subtitle-2 f-w-600 m-b-16">
    {{ fullName }}
  </h6>
</div>

<div class="d-flex align-items-center justify-content-center">
  <branch-search [data]="branches" [branchConfig]="branchConfig" (result)="branchChanged($event.value)"
    [wideWidth]="!fieldQuery.matches">
  </branch-search>
</div>

<div class="d-flex align-items-center justify-content-center">
  <div class="release_and_image_container">
    <img [src]="userImagePath" onerror="this.src='./assets/images/userpic.png'"
      style="width: 72px; height: 72px; border-radius: 50%" alt="" />
  </div>
</div>
<div class="d-flex align-items-center justify-content-center">
  <button (click)="navTo()" mat-raised-button tourAnchor="side.first" color="primary" class="manage-account"
    [ngClass]="{ 'btn-is-active': activeScreenId == -1 }">
    {{ "SIDENAV.MANAGE_ACCOUNT" | translate }}
  </button>
</div>

}
<!-- link side bar  -->
<div tourAnchor="side.second">
  <mat-nav-list class="sidebar-list">
    @for (item of sideMenu; track item) {
    <!--      <div *ngIf="!folded" class="row menu-list-item"  (click)="toggleChildren(item?.id)"-->
    <!--           [class.rtl-item]="_translate.currentLang === 'ar'" [class.ltr-item]="_translate.currentLang !== 'ar'">-->
    <!--        <div class="col-sm-1 item-icon">-->
    <!--          <mat-icon>{{ item?.fontIcon }}</mat-icon>-->
    <!--        </div>-->
    <!--        <div class="col-sm-9 item-text">-->
    <!--          <span class="hide-menu" *ngIf="_translate.currentLang === 'ar'">{{ item?.nameAr }}</span>-->
    <!--          <span class="hide-menu" *ngIf="_translate.currentLang === 'en'">{{ item?.nameEn }}</span>-->
    <!--          <span class="hide-menu" *ngIf="_translate.currentLang === 'fr'">{{ item?.nameFr }}</span>-->
    <!--        </div>-->
    <!--        <div class="col-sm-2">-->
    <!--          <mat-icon class="arrow-icon"><span *ngIf="!item?.active">-->
    <!--            <span *ngIf="_translate.currentLang === 'ar'" style="font-size: 20px;">keyboard_arrow_left</span>-->
    <!--            <span *ngIf="_translate.currentLang !== 'ar'" style="font-size: 20px;">keyboard_arrow_right</span>-->
    <!--          </span>-->
    <!--            <span *ngIf="item?.active" style="font-size: 20px;">keyboard_arrow_down</span>-->
    <!--          </mat-icon>-->
    <!--        </div>-->
    <!--      </div>-->

    @if (!folded) {
    <a mat-list-item (click)="toggleChildren(item?.id)" [ngClass]="{
        'mat-primary activeMenu': item.active
      }" class="menu-list-item">
      <div class="col-1 col-sm-1">
        @if (item.active) {
        <mat-icon class="side-nav-icon-is-active">{{ item?.fontIcon }}</mat-icon>
        } @else {
        <mat-icon color="primary" class="side-nav-icon-is-not-active">{{ item?.fontIcon }}</mat-icon>
        }
      </div>
      <div class="col-9 col-sm-9 item-text">
        <span class="hide-menu" *ngIf="_translate.currentLang === 'ar'">{{
          item?.nameAr
          }}</span>
        <span class="hide-menu" *ngIf="_translate.currentLang === 'en'">{{
          item?.nameEn
          }}</span>
        <span class="hide-menu" *ngIf="_translate.currentLang === 'fr'">{{
          item?.nameFr
          }}</span>
      </div>
      <div>
        @if (!item?.active) {
        <mat-icon color="primary" class="arrow-icon">
          <span *ngIf="_translate.currentLang === 'ar'" style="font-size: 20px">keyboard_arrow_left</span>
          <span *ngIf="_translate.currentLang !== 'ar'" style="font-size: 20px">keyboard_arrow_right</span>
        </mat-icon>
        } @else {
        <mat-icon class="arrow-icon">
          <span *ngIf="item?.active">keyboard_arrow_down</span>
        </mat-icon>
        }
      </div>
    </a>
    }


    <!--  -->
    <div *ngIf="item?.screens?.length && item?.active">
      <a *ngFor="let screen of item?.screens" mat-list-item class="sub-item" [ngClass]="{
          'mat-toolbar mat-primary activeMenu': screen.id == activeScreenId
        }" (click)="changeRoute(screen?.routingPath, screen.id)">
        <div>
          <span *ngIf="!folded && _translate.currentLang === 'ar'">
            <span class="dot"></span> {{ screen?.nameAr }}</span>
          <span *ngIf="!folded && _translate.currentLang === 'en'">
            <span class="dot"></span> {{ screen?.nameEn }}</span>
          <span *ngIf="!folded && _translate.currentLang === 'fr'">
            <span class="dot"></span> {{ screen?.nameFr }}</span>
        </div>
      </a>
    </div>




    <!--  -->
    <div *ngIf="folded" class="row item" [matMenuTriggerFor]="subMenu">
      <div class="col-sm-1 item-icon">
        @if (item.active) {
        <mat-icon>{{ item?.fontIcon }}</mat-icon>
        } @else {
        <mat-icon color="primary">{{ item?.fontIcon }}</mat-icon>
        }
      </div>
    </div>
    <mat-menu #subMenu="matMenu">
      <a *ngFor="let screen of item?.screens" mat-menu-item [ngClass]="{
          'mat-toolbar mat-primary activeMenu': screen.id == activeScreenId
        }" (click)="changeRoute(screen?.routingPath, screen.id)">
        <span *ngIf="_translate.currentLang === 'ar'">{{
          screen?.nameAr
          }}</span>
        <span *ngIf="_translate.currentLang === 'en'">{{
          screen?.nameEn
          }}</span>
        <span *ngIf="_translate.currentLang === 'fr'">{{
          screen?.nameFr
          }}</span>
      </a>
    </mat-menu>
    <span class="sidebar-divider"></span>
    }
  </mat-nav-list>

  <br />
</div>
<!-- end side bar -->
<div class="social" *ngIf="!folded">
  <button style="margin: 0 3px" mat-mini-fab color="primary" (click)="openStoreDialog('appGallery')"
    class="d-flex justify-content-center">
    <i class="iconify icon-20 d-flex" data-icon="cib:huawei"></i>
  </button>

  <button style="margin: 0 3px" mat-mini-fab color="primary" (click)="openStoreDialog('appStore')"
    class="d-flex justify-content-center">
    <i class="iconify icon-20 d-flex" data-icon="cib:apple"></i>
  </button>

  <button style="margin: 0 3px" mat-mini-fab color="primary" (click)="openStoreDialog('googlePlay')"
    class="d-flex justify-content-center">
    <i class="iconify icon-20 d-flex" data-icon="cib:google-play"></i>
  </button>
</div>
<div style="display: flex; justify-content: center; margin-bottom: 10px" *ngIf="!folded">
  <button (click)="guidelineService.start()" mat-flat-button color="primary">
    {{ "GUIDE.SYSTEM_GUIDE" | translate }}
    <mat-icon>help</mat-icon>
  </button>
</div>

@if (folded) {
<button style="margin: 0 5px 10px 5px" mat-mini-fab color="primary" (click)="guidelineService.start()"
  class="d-flex justify-content-center">
  <i class="iconify icon-20 d-flex" data-icon="ic:twotone-help"></i>
</button>

}

<div *ngIf="!folded" style="display: flex; justify-content: center; margin-bottom: 10px">
  <button mat-flat-button color="primary" style="cursor: pointer" (click)="openReleaseNotesDialog()">
    {{ "COMMON.VERSION_NUMBER" | translate }}
    {{ versionNumber }}
  </button>
</div>

<div style="display: flex; justify-content: center" *ngIf="!folded">
  <button (click)="logout()" mat-flat-button color="primary">
    {{ "TOOLBAR.LOGOUT" | translate }}
    <mat-icon>logout</mat-icon>
  </button>
</div>

@if (folded) {
<button style="margin: 0 5px" mat-mini-fab color="primary" (click)="logout()" class="d-flex justify-content-center">
  <i class="iconify icon-20 d-flex" data-icon="solar:logout-line-duotone"></i>
</button>
}
<div class="version-container" *ngIf="isVersionChanged">
  <img src="assets/images/version.png" alt="">
  <p>V{{ versionNumber }} is available</p>
  <button class="release-note" (click)="removeNote()">Release Note</button>
</div>