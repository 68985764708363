import { Component,Input,Inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-motion',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule
  ],
  templateUrl: './motion.component.html',
  styleUrls: ['./motion.component.scss']
})
export class MotionComponent {
  @Input() isVisible: boolean = false;
  customMessage: string;

  constructor(
    public _translate: TranslateService,
    public dialogRef: MatDialogRef<MotionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isVisible = data.isVisible || false;
}
closePopup() {
  this.isVisible = false;
  this.dialogRef.close();
}
  }


