
.check_card {
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-radius: 0.75rem;
  width: 22.5rem !important;

  .check_card_header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 1rem;
    background-color: #6a1b9a;
    color: #ffffff;
    justify-content: space-between;

    .header_title {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 2.5rem;
    }
  }

  .check_card_content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-top-width: 0px;
    border-bottom-width: 1px;
    overflow-y: auto;
    background-color: #FFFFFF;
    padding: 10px 5px;

    .shift_content {
      border-radius: 5px;
      border: 1px dashed var(--gray2);
      padding: 10px 0;

      .shift_info {
        margin: 6px 0;
        padding: 5px;
        align-items: center;

        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }

        .shift_info_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background-color: #E1C1FF;

          mat-icon {
            width: 28px;
            height: 28px;
            font-size: 28px;
            color: var(--purple-dark);
          }
        }

        .shift_info_title {
          font-size: 1rem;
          font-weight: 500;
        }

        .shift_info_time {
          font-size: 0.725rem;
          color: var(--gray5);
        }

        .shift_name {
          background-color: #E1C1FF;
          padding: 3px 8px;
          border-radius: 8px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
        }
      }

      .current_shift_info {
        margin: 10px 0;
        padding: 5px;
        justify-content: center;
        text-align: center;

        .current_shift_info_card {
          background-color: #6777EF;
          border-radius: 8px;
          padding: 2px 10px;
          color: white;
          margin: 0 5px;

          .shift_info_title {
            font-size: 0.725rem;
            color: white;
            font-weight: 500;
          }

          .shift_info_time {
            font-size: 0.725rem;
            color: white;
            font-weight: 500;
          }
        }
      }

      .check_action {
        display: flex;
        justify-content: center;
        margin: 10px 0;
      }
    }

    .check_note {
      background-color: #6777EF;
      color: white;
      padding: 3px 8px;
      margin: 20px auto;
      border-radius: 8px;
      text-align: center;

      p {
        font-size: 12px;
      }
    }

    .results {
      margin: 10px 0;
      padding: 15px;
      background-color: #6777EF;
      color: #fff;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      small {
        font-weight: bolder;
        display: block;
        font-size: 60%;
        margin: -.6em 0 0 0;
      }

      button {
        background: none;
        line-height: 1;
        font-weight: bolder;
        font-size: 120%;
        $mg: 8px;
        margin: $mg;
      }
    }

    .check_in_icon {
      //border-radius: 12px;
      //border: 1px solid white;
      padding: 5px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center
    }
    .cancel_check_in_icon {
      padding: 5px;
      min-width: 30px;
      display: flex;
      align-items: center;
      color: var(--red);
      justify-content: center
    }

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
      height: 40px !important;
      padding-bottom: 0;
      font-size: 14px;
    }

    .mat-form-field-flex {
      height: 40px !important;
    }
  }


    .super_icon {
      align-items: center;
      mat-icon {
        width: 28px;
        height: 28px;
        font-size: 28px;
        color: white;

      }
  }

  @media screen and (max-width: 450px) {
    position: fixed !important;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 0;
    width: 100% !important;
  }

}
